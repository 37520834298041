export { matchers } from "/.svelte-kit/generated/client/matchers.js";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js"),
	() => import("/.svelte-kit/generated/client/nodes/1.js"),
	() => import("/.svelte-kit/generated/client/nodes/2.js"),
	() => import("/.svelte-kit/generated/client/nodes/3.js"),
	() => import("/.svelte-kit/generated/client/nodes/4.js"),
	() => import("/.svelte-kit/generated/client/nodes/5.js"),
	() => import("/.svelte-kit/generated/client/nodes/6.js"),
	() => import("/.svelte-kit/generated/client/nodes/7.js")
];

export const server_loads = [2];

export const dictionary = {
		"/[[lang]]/page/[id]": [~4,[2]],
		"/[[lang]]/page/[id]/presentation_set/[setId]": [~5,[2]],
		"/[[lang]]/page/[id]/presentation_set/[setId]/presentation/[presentationId]": [~6,[2]],
		"/[[lang]]/page/[id]/presentation_set/[setId]/presentation/[presentationId]/slide/[slideId]": [~7,[2]],
		"/[[lang]]": [3,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from "/.svelte-kit/generated/root.js";